// Custom variables (including functions and mix-ins)
// E.g., p { @import font-size($h1-font-size); }
@import "~themes/bootstrap-variables";

html,
body,
#root {
  height: 100%;
}

body {
  // color: darken($info-dark, 10%);
}

// :global {
//   #root {
//     height: 100%;
//   }
// }

@include media-breakpoint-up(md) {
  html {
    font-size: 17px;
  }
}

// @include media-breakpoint-up(lg) {
//   html {
//     font-size: 18px;
//   }
// }

// @include media-breakpoint-up(xl) {
//   html {
//     font-size: 19px;
//   }
// }

h1, h2, h3, h4, h5 {
  font-weight: bold;
}

a {
  color: $primary-dark;
}

.box-shadow {
  box-shadow: 0 0.75em 1.75rem 0 $black-15
}

.svg-shadow {
  filter: drop-shadow(0 0.25rem 0.5rem $black-15);
}

.ui {
  font-weight: bold;
}
