// Bootstrap functions, variables, and mixins

@import "~bootstrap/scss/functions";

// Override default variables
$white-85: adjust-color(white, $alpha: -0.15);
$white-70: adjust-color(white, $alpha: -0.3);
$white-50: adjust-color(white, $alpha: -0.5);
$black-50: adjust-color(black, $alpha: -0.5);
$black-30: adjust-color(black, $alpha: -0.7);
$black-15: adjust-color(black, $alpha: -0.85);

$primary-light: #B2EBF2;
$primary: #00ACC1;
$primary-dark: #00838F;
$secondary-light: #EBCDAD;
$secondary: #D79B5B;
$secondary-dark: #6D4C41;
$bg: #EFEFF4;

// $primary: #00b37a;
// $primary-dark: #2f5f4f;
// $secondary: #ebcdad;
// $secondary-dark: #934e03;
$success: #00b37a;
$info: #008cff;
// $info-dark: #2a5570;
// $light: #6c757d;
$theme-colors: (
  "primary-light": $primary-light,
  "primary-dark": $primary-dark,
  "secondary-light": $secondary-light,
  "secondary-dark": $secondary-dark,
  // "info-dark": $info-dark,
  "white": white,
  "white-85": $white-85,
  "white-70": $white-70,
  "white-50": $white-50,
  "black": black,
  "black-50": $black-50,
  "black-30": $black-30,
  "black-15": $black-15,
);

$spacer: 1rem;
$spacers: (
  6: (
    $spacer * 5
  ),
  7: (
    $spacer * 8
  ),
  8: (
    $spacer * 11
  ),
  9: (
    $spacer * 14
  ),
  10: (
    $spacer * 17
  ),
  11: (
    $spacer * 20
  ),
  12: (
    $spacer * 23
  )
);

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 3.5;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 2;

$enable-shadows: false;
$enable-gradients: false;
$enable-responsive-font-sizes: true;

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Allows JS to read variables via ICSS (https://github.com/css-modules/icss).
// Exported variables must contain valid CSS declaration values.
:export {
  // brownColor: $brown;
}
